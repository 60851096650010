<template>
    <div>
        <ProfessorSearch redirectToRate />

        <div v-if="selectedProfessor">
            <h1>Rate: {{(selectedProfessor.title) ? selectedProfessor.title + ' ' : ''}}{{selectedProfessor.lname + ', ' + selectedProfessor.fname}}</h1>
            <Rating :professor="selectedProfessor" />
        </div>
    </div>
</template>

<script>
import ProfessorSearch from '../components/profcheck/ProfessorSearch.vue';
import Rating from '../components/profcheck/Rating.vue';
import { useProfStore } from '@/store/prof.store';

export default {
    setup: () => {
        const store = useProfStore();

        return {
            selectedProfessor: null,
            store,
        }
    },
    async created() {
        if (this.store.selected) {
            this.selectedProfessor = this.store.selected;
        } else {
            this.$router.push('/professors');
        }
    },
    components: { ProfessorSearch, Rating }
}
</script>

<style scoped>
div > h1 {
    color: #5bbdf4;
    font-family: "Sora", sans-serif;
    font-size: max(3.5vw, 1.8em);
    background-color: #fcf5eb;
    text-align: center;
    padding: 1em;
}
</style>