<template>
  <div>
    <div :class="rowStyle">
      <div class="col-12 text-center">
        <p class="text-body1 text-grey-8">
          5 = applies strongly, 1 = doesn't apply at all
        </p>
      </div>
      <div
        class="col-xs-12 col-md-6"
        v-for="(factor, i) in factors"
        v-bind:key="i"
      >
        <div class="q-pa-md shadow-1">
          <h4>{{ factor.title }}</h4>
          <p>{{ factor.criteria }}</p>
          <q-rating
            v-model="factor.value"
            size="2em"
            :max="5"
            color="primary"
          ></q-rating>
        </div>
      </div>
    </div>

    <div class="send-button">
      <q-btn @click="onSend" :disabled="factors.some((c) => c.value == 0)"
        >Post</q-btn
      >
    </div>
  </div>
</template>


<script>
import { useUserStore } from "@/store/user.store";
import { ref } from "vue";
import { useProfStore } from "../../store/prof.store";

export default {
  props: {
    professor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const profStore = useProfStore();

    return {
      profStore,
      userStore: useUserStore(),
      factors: ref([]),
    };
  },
  computed: {
    rowStyle() {
      return this.$q.platform.is.desktop
        ? "row q-ma-lg q-col-gutter-lg"
        : " row q-ma-lg q-col-gutter-sm";
    },
  },
  methods: {
    async onSend() {
      const data = {
        prof_id: this.professor._id,
        factors: this.factors,
      };

      const notif = this.$q.notify({
        timeout: 0,
        group: false,
        color: "primary",
        message: `Posting rating for ${this.professor.title ?? ""} ${
          this.professor.fname
        } ${this.professor.lname}...`,
        position: "bottom",
      });

      try {
        const response = await this.profStore.postRating(data);

        // @debug
        console.log("Rating Response", response);

        if (response.status === 200 || response.status === 201) {
          notif({
            message: `Your rating has been ${
              response.status === 200 ? "updated" : "posted"
            }, thank you!`,
            color: "positive",
            icon: "check",
            timeout: 1000,
          });

          this.$router.push("/professor");
        } else {
          console.error(response);
          notif({
            message: "Posting of rating failed...",
            color: "negative",
            icon: "close",
            timeout: 1000,
          });
        }
      } catch (e) {
        console.error(e);
        notif({
          message: "Posting of rating failed...",
          color: "negative",
          icon: "close",
          timeout: 1000,
        });
      }
    },
  },
  async mounted() {
    // load factors from api
    const response = await this.profStore.fetchFactors();

    if (response.status === 200) {
      this.factors.length = 0;
      this.factors.push(...response.data);
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display&display=swap");

.value-ques-const {
  display: grid;
  justify-content: space-between;
  grid-gap: 50px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(500px, 0.5fr));
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
}

.value-ques {
  font-family: "Sora", sans-serif;
  padding: 2%;
  border: solid 2px #5bbdf4;
  border-radius: 6.78px;
}

.value-ques h1 {
  color: #5bbdf4;
  font-size: max(2vw, 1.5em);
}

.value-ques p {
  color: #555555;
  margin-bottom: 10%;
}

.send-button {
  margin-top: 5%;
  margin-right: 10%;
  float: right;
}

.send-button > button {
  background-color: #5bbdf4;
  color: white;
  border: none;
  border-radius: 4.78px;
  font-size: max(1vw, 1em);
  cursor: pointer;
}
</style>